import { useCallback } from "react";

import {
    ConsumerTripEventWLQuesitonnaireBackClickedCreateInput,
    ConsumerTripEventWLQuesitonnaireClosedCreateInput,
    ConsumerTripEventWLQuesitonnaireInterestSelectedCreateInput,
    ConsumerTripEventWLQuesitonnaireInterestUnselectedCreateInput,
    ConsumerTripEventWLQuesitonnaireNextClickedCreateInput,
    ConsumerTripEventWLQuesitonnaireOpenedCreateInput,
} from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreate } from "./useConsumerTripEventCreate";

type ConsumerTripEventQuestionnaireProps = {
    consumerTripId: string;
};

function useConsumerTripEventQuesitonnaireOpened({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireOpenedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_OPENED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireInterestSelected({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireInterestSelectedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_INTEREST_SELECTED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireInterestUnselected({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireInterestUnselectedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_INTEREST_UNSELECTED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireNextClicked({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireNextClickedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_NEXT_CLICKED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireSubmitted({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(async () => {
        await consumerTripEventCreate({
            meta: {},
            consumerTripId,
            type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_SUBMITTED",
        });
    }, [consumerTripEventCreate, consumerTripId]);
}

function useConsumerTripEventQuesitonnaireChangeOfHeartClicked({
    consumerTripId,
}: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(async () => {
        await consumerTripEventCreate({
            meta: {},
            consumerTripId,
            type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_CHANGE_OF_HEART_CLICKED",
        });
    }, [consumerTripEventCreate, consumerTripId]);
}

function useConsumerTripEventQuesitonnaireClosed({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireClosedCreateInput["meta"]) => {
            await consumerTripEventCreate({ meta, consumerTripId, type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_CLOSED" });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventQuesitonnaireBackClicked({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (meta: ConsumerTripEventWLQuesitonnaireBackClickedCreateInput["meta"]) => {
            await consumerTripEventCreate({
                meta,
                consumerTripId,
                type: "WL_CONSUMER_TRIP_QUESTIONNAIRE_BACK_CLICKED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

export function useConsumerTripEventQuestionnaire({ consumerTripId }: ConsumerTripEventQuestionnaireProps) {
    const consumerTripEventQuesitonnaireOpened = useConsumerTripEventQuesitonnaireOpened({ consumerTripId });
    const consumerTripEventQuesitonnaireInterestSelected = useConsumerTripEventQuesitonnaireInterestSelected({
        consumerTripId,
    });
    const consumerTripEventQuesitonnaireInterestUnselected = useConsumerTripEventQuesitonnaireInterestUnselected({
        consumerTripId,
    });
    const consumerTripEventQuesitonnaireNextClicked = useConsumerTripEventQuesitonnaireNextClicked({ consumerTripId });
    const consumerTripEventQuesitonnaireSubmitted = useConsumerTripEventQuesitonnaireSubmitted({ consumerTripId });
    const consumerTripEventQuesitonnaireChangeOfHeartClicked = useConsumerTripEventQuesitonnaireChangeOfHeartClicked({
        consumerTripId,
    });
    const consumerTripEventQuesitonnaireClosed = useConsumerTripEventQuesitonnaireClosed({ consumerTripId });
    const consumerTripEventQuesitonnaireBackClicked = useConsumerTripEventQuesitonnaireBackClicked({ consumerTripId });

    return {
        consumerTripEventQuesitonnaireOpened,
        consumerTripEventQuesitonnaireInterestSelected,
        consumerTripEventQuesitonnaireInterestUnselected,
        consumerTripEventQuesitonnaireNextClicked,
        consumerTripEventQuesitonnaireSubmitted,
        consumerTripEventQuesitonnaireChangeOfHeartClicked,
        consumerTripEventQuesitonnaireClosed,
        consumerTripEventQuesitonnaireBackClicked,
    };
}
