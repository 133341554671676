import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as GQLTypes from "@holibob-packages/graphql-types";

const defaultOptions = {} as const;
export type ConsumerTripEventCreateMutationVariables = GQLTypes.Exact<{
    consumerTripId: GQLTypes.Scalars["String"]["input"];
    type: GQLTypes.Scalars["String"]["input"];
    meta: GQLTypes.Scalars["JSON"]["input"];
}>;

export type ConsumerTripEventCreateMutation = { __typename: "Mutations"; consumerTripEventCreate: boolean };

export type BrandColourPresetFragment = { __typename: "BrandColourPreset"; main: string; contrast: string };

export type BrandForThemeFragment = {
    __typename: "Brand";
    primaryFont: string | null;
    headerLogoPaddingTop: number | null;
    headerLogoPaddingBottom: number | null;
    airiness: number;
    roundness: number;
    specialFont:
        | {
              __typename: "FontCustom";
              bold: { __typename: "Asset"; uri: string } | null;
              boldItalic: { __typename: "Asset"; uri: string } | null;
              medium: { __typename: "Asset"; uri: string } | null;
              mediumItalic: { __typename: "Asset"; uri: string } | null;
              regular: { __typename: "Asset"; uri: string };
              regularItalic: { __typename: "Asset"; uri: string } | null;
          }
        | { __typename: "FontExternal"; name: string }
        | null;
    headerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    secondaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    footerColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    primaryColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    primaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    secondaryButtonColour: { __typename: "BrandColourPreset"; main: string; contrast: string };
    spotColours: Array<{
        __typename: "BrandSpotColour";
        shade40: string;
        shade70: string;
        shade100: string;
        shade400: string;
        shade700: string;
        index: number;
    }>;
};

type Font_FontCustom_Fragment = {
    __typename: "FontCustom";
    bold: { __typename: "Asset"; uri: string } | null;
    boldItalic: { __typename: "Asset"; uri: string } | null;
    medium: { __typename: "Asset"; uri: string } | null;
    mediumItalic: { __typename: "Asset"; uri: string } | null;
    regular: { __typename: "Asset"; uri: string };
    regularItalic: { __typename: "Asset"; uri: string } | null;
};

type Font_FontExternal_Fragment = { __typename: "FontExternal"; name: string };

export type FontFragment = Font_FontCustom_Fragment | Font_FontExternal_Fragment;

export type FontCustomFragment = {
    __typename: "FontCustom";
    bold: { __typename: "Asset"; uri: string } | null;
    boldItalic: { __typename: "Asset"; uri: string } | null;
    medium: { __typename: "Asset"; uri: string } | null;
    mediumItalic: { __typename: "Asset"; uri: string } | null;
    regular: { __typename: "Asset"; uri: string };
    regularItalic: { __typename: "Asset"; uri: string } | null;
};

export const FontCustomFragmentDoc = gql`
    fragment FontCustom on FontCustom {
        bold {
            uri
        }
        boldItalic {
            uri
        }
        medium {
            uri
        }
        mediumItalic {
            uri
        }
        regular {
            uri
        }
        regularItalic {
            uri
        }
    }
`;
export const FontFragmentDoc = gql`
    fragment Font on Font {
        ... on FontExternal {
            __typename
            name
        }
        ... on FontCustom {
            ...FontCustom
        }
    }
    ${FontCustomFragmentDoc}
`;
export const BrandColourPresetFragmentDoc = gql`
    fragment BrandColourPreset on BrandColourPreset {
        main
        contrast
    }
`;
export const BrandForThemeFragmentDoc = gql`
    fragment BrandForTheme on Brand {
        primaryFont
        headerLogoPaddingTop
        headerLogoPaddingBottom
        specialFont {
            ...Font
        }
        headerColour {
            ...BrandColourPreset
        }
        secondaryColour {
            ...BrandColourPreset
        }
        footerColour {
            ...BrandColourPreset
        }
        primaryColour {
            ...BrandColourPreset
        }
        primaryButtonColour {
            ...BrandColourPreset
        }
        secondaryButtonColour {
            ...BrandColourPreset
        }
        spotColours {
            shade40
            shade70
            shade100
            shade400
            shade700
            index
        }
        airiness
        roundness
    }
    ${FontFragmentDoc}
    ${BrandColourPresetFragmentDoc}
`;
export const ConsumerTripEventCreateDocument = gql`
    mutation ConsumerTripEventCreate($consumerTripId: String!, $type: String!, $meta: JSON!) {
        consumerTripEventCreate(consumerTripId: $consumerTripId, type: $type, meta: $meta)
    }
`;
export type ConsumerTripEventCreateMutationFn = Apollo.MutationFunction<
    ConsumerTripEventCreateMutation,
    ConsumerTripEventCreateMutationVariables
>;
export function useConsumerTripEventCreateMutation(
    baseOptions?: Apollo.MutationHookOptions<ConsumerTripEventCreateMutation, ConsumerTripEventCreateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<ConsumerTripEventCreateMutation, ConsumerTripEventCreateMutationVariables>(
        ConsumerTripEventCreateDocument,
        options
    );
}
export type ConsumerTripEventCreateMutationHookResult = ReturnType<typeof useConsumerTripEventCreateMutation>;
export type ConsumerTripEventCreateMutationResult = Apollo.MutationResult<ConsumerTripEventCreateMutation>;
export type ConsumerTripEventCreateMutationOptions = Apollo.BaseMutationOptions<
    ConsumerTripEventCreateMutation,
    ConsumerTripEventCreateMutationVariables
>;
