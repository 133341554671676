import { useCallback } from "react";

import type { ConsumerTripEventWLProductClickInput } from "@holibob-packages/consumer-trip-event";

import {
    ConsumerTripEventRegistrationProps,
    useConsumerTripEventRegistration,
} from "./useConsumerTripEventRegistration";

export function useConsumerTripProductClickEventRegistration(props: ConsumerTripEventRegistrationProps) {
    const register = useConsumerTripEventRegistration(props);
    return useCallback(
        (meta: ConsumerTripEventWLProductClickInput["meta"]) => {
            return register({
                type: "WL_PRODUCT_CLICK",
                meta: meta,
            });
        },
        [register]
    );
}
