import { useCallback } from "react";

import { ConsumerTripEventWLSearchTriggeredCreateInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreate } from "./useConsumerTripEventCreate";

export function useConsumerTripEventWLSearchTriggered({ consumerTripId }: { consumerTripId: string }) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        (params: ConsumerTripEventWLSearchTriggeredCreateInput["meta"]) => {
            return consumerTripEventCreate({ consumerTripId, type: "WL_SEARCH_TRIGGERED", meta: params });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}
