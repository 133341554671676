import { useCallback } from "react";

import { ConsumerTripBookingPaidCreateInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreateMutation } from "../../apiHooks/graphql";

export function useConsumerTripBookingPaidEvent({ consumerTripId }: { consumerTripId: string }) {
    const [mutate] = useConsumerTripEventCreateMutation();

    return useCallback(
        ({ itemList, currency, price }: ConsumerTripBookingPaidCreateInput["meta"]) => {
            return mutate({
                variables: {
                    consumerTripId,
                    type: "WL_BOOKING_PAID",
                    meta: {
                        itemList,
                        currency,
                        price,
                    },
                },
            });
        },
        [consumerTripId, mutate]
    );
}
