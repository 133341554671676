"use client";

import { useEffect, useRef } from "react";

import { Subscription, useCustomDOMEventHandler } from "../hooks";

type UseEventSubscriptionProps = {
    eventSubscriptionList: Iterable<(subscription: Subscription) => void>;
    onInitialised?: () => void;
};

export function useEventSubscription({ eventSubscriptionList, onInitialised }: UseEventSubscriptionProps) {
    const ref = useRef<HTMLElement | null>(null);

    useEffect(() => {
        ref.current = document.body;
    }, []);

    useCustomDOMEventHandler(ref, (subscription) => {
        for (const item of eventSubscriptionList) {
            item(subscription);
        }

        onInitialised?.();
    });
}
