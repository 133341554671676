import { useCallback } from "react";

import {
    ConsumerTripEventWLStoryClickedCreateInput,
    ConsumerTripEventWLStoryDisplayedCreateInput,
    ConsumerTripEventWLStoryScrolledCreateInput,
} from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreate } from "./useConsumerTripEventCreate";

function useConsumerTripEventCreateStoryClicked({ consumerTripId }: UseConsumerTripEventStoryProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (shelfType: ConsumerTripEventWLStoryClickedCreateInput["meta"]["shelfType"]) => {
            await consumerTripEventCreate({
                consumerTripId,
                meta: { shelfType },
                type: "WL_CONSUMER_TRIP_STORY_CLICKED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventCreateStoryScrolled({ consumerTripId }: UseConsumerTripEventStoryProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (shelfType: ConsumerTripEventWLStoryScrolledCreateInput["meta"]["shelfType"]) => {
            await consumerTripEventCreate({
                meta: { shelfType },
                consumerTripId,
                type: "WL_CONSUMER_TRIP_STORY_SCROLLED",
            });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

function useConsumerTripEventCreateStoryDisplayed({ consumerTripId }: UseConsumerTripEventStoryProps) {
    const consumerTripEventCreate = useConsumerTripEventCreate();

    return useCallback(
        async (meta: ConsumerTripEventWLStoryDisplayedCreateInput["meta"]) => {
            await consumerTripEventCreate({ meta, consumerTripId, type: "WL_CONSUMER_TRIP_STORY_DISPLAYED" });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

type UseConsumerTripEventStoryProps = {
    consumerTripId: string;
};
export function useConsumerTripEventStory(props: UseConsumerTripEventStoryProps) {
    const consumerTripEventCreateStoryClicked = useConsumerTripEventCreateStoryClicked(props);
    const consumerTripEventCreateStoryScrolled = useConsumerTripEventCreateStoryScrolled(props);
    const consumerTripEventCreateStoryDisplayed = useConsumerTripEventCreateStoryDisplayed(props);

    return {
        consumerTripEventCreateStoryClicked,
        consumerTripEventCreateStoryScrolled,
        consumerTripEventCreateStoryDisplayed,
    };
}
