import { useCallback } from "react";

import { useConsumerTripEventCreateMutation } from "../../apiHooks/graphql";

export type ConsumerTripEventInput = {
    type: string;
    meta: Record<string, unknown>;
};

export type ConsumerTripEventRegistrationProps = {
    consumerTripId: string;
};
export function useConsumerTripEventRegistration({ consumerTripId }: ConsumerTripEventRegistrationProps) {
    const [mutate] = useConsumerTripEventCreateMutation();

    return useCallback(
        (input: ConsumerTripEventInput) => {
            return mutate({
                variables: {
                    consumerTripId,
                    ...input,
                },
            });
        },
        [mutate, consumerTripId]
    );
}
