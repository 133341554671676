"use client";

import { useHBMLComponentShelfScrolledEvent } from "hooks/api/useHBMLComponentShelfScrolledEvent";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useEffect, useRef } from "react";

import {
    useConsumerTripBookingPaidEvent,
    useConsumerTripEventQuestionnaire,
    useConsumerTripEventStory,
    useConsumerTripEventWLSearch,
    useConsumerTripEventWLSearchTriggered,
    useConsumerTripProductClickEventRegistration,
    useEventSubscription,
    usePersonalizationShelfDisplayedEvent,
    usePersonalizationShelfScrolledEvent,
} from "@holibob-packages/analytics";
import {
    BookingPaidEvent,
    CurationProductClickEvent,
    DestinationProductSearchClickEvent,
    DestinationSearchEvent,
    GlobalSearchEvent,
    HbmlShelfFirstScrollEvent,
    QuestionnaireBackClickEvent,
    QuestionnaireChangeOfHeartClickEvent,
    QuestionnaireCloseEvent,
    QuestionnaireInterestSelectEvent,
    QuestionnaireInterestUnselectEvent,
    QuestionnaireNextClickEvent,
    QuestionnaireOpenEvent,
    QuestionnaireSubmitEvent,
    SearchProductClickEvent,
    SearchTriggerEvent,
    ShelfDisplayEvent,
    ShelfFirstScrollEvent,
    ShelfProductClickEvent,
    StoryClickEvent,
    StoryDisplayEvent,
    StoryProductClickEvent,
    StoryProductsScrollEvent,
} from "@holibob-packages/ui-core/custom-events";

type ConsumerTripEventProps = {
    children: React.ReactNode;
};

export const ConsumerTripEvent = ({ children }: ConsumerTripEventProps) => {
    const ref = useRef<HTMLElement | null>(null);

    useEffect(() => {
        ref.current = document.body;
    }, []);

    const { consumerTripId } = useConsumerTripInfo();

    const personalizationShelfDisplayedEvent = usePersonalizationShelfDisplayedEvent({ consumerTripId });
    const consumerTripEventCreateShelfScrolled = usePersonalizationShelfScrolledEvent({ consumerTripId });
    const consumerTripEventProductClick = useConsumerTripProductClickEventRegistration({ consumerTripId });
    const consumerTripEventQuestionnaire = useConsumerTripEventQuestionnaire({ consumerTripId });
    const consumerTripEventStory = useConsumerTripEventStory({ consumerTripId });
    const consumerTripEventWLSearch = useConsumerTripEventWLSearch({ consumerTripId });
    const consumerTripEventWLSearchTriggered = useConsumerTripEventWLSearchTriggered({ consumerTripId });
    const consumerTripBookingPaid = useConsumerTripBookingPaidEvent({ consumerTripId });
    const hbmlComponentShelfScrolled = useHBMLComponentShelfScrolledEvent({ consumerTripId });

    useEventSubscription({
        eventSubscriptionList: [
            // Booking events
            (subscription) => {
                subscription.on(BookingPaidEvent, (event) => {
                    void consumerTripBookingPaid(event.details);
                });
            },

            // Curation events
            (subscription) => {
                subscription.on(CurationProductClickEvent, (event) => {
                    void consumerTripEventProductClick({
                        itemId: event.details.item.id,
                        itemPosition: event.details.position,
                        productOrigin: "CURATION",
                    });
                });
            },

            // Story events
            (subscription) => {
                subscription.on(StoryDisplayEvent, (event) => {
                    void consumerTripEventStory.consumerTripEventCreateStoryDisplayed(event.details);
                });
                subscription.on(StoryClickEvent, (event) => {
                    void consumerTripEventStory.consumerTripEventCreateStoryClicked(event.shelfType);
                });
                subscription.on(StoryProductClickEvent, (event) => {
                    const { item, position, shelfType } = event.details;
                    void consumerTripEventProductClick({
                        itemId: item.id,
                        itemPosition: position,
                        shelfType,
                        productOrigin: "STORY",
                    });
                });
                subscription.on(StoryProductsScrollEvent, (event) => {
                    void consumerTripEventStory.consumerTripEventCreateStoryScrolled(event.shelfType);
                });
            },

            // Shelf events
            (subscription) => {
                subscription.on(ShelfDisplayEvent, (event) => {
                    void personalizationShelfDisplayedEvent(event.details);
                });
                subscription.on(ShelfFirstScrollEvent, (event) => {
                    void consumerTripEventCreateShelfScrolled(event.shelfType);
                });
                subscription.on(ShelfProductClickEvent, (event) => {
                    const { item, position, shelfType } = event.details;
                    void consumerTripEventProductClick({
                        itemId: item.id,
                        itemPosition: position,
                        shelfType,
                        productOrigin: "PERSONALIZATION_SHELF",
                    });
                });
            },

            // Search events
            (subscription) => {
                subscription.on(SearchTriggerEvent, (event) => {
                    void consumerTripEventWLSearchTriggered({
                        searchButtonType: event.details.searchButtonType,
                    });
                });
                subscription.on(GlobalSearchEvent, (event) => {
                    void consumerTripEventWLSearch({
                        phrase: event.phrase,
                        searchType: "GLOBAL_SEARCH",
                    });
                });
                subscription.on(DestinationSearchEvent, (event) => {
                    void consumerTripEventWLSearch({
                        phrase: event.phrase,
                        searchType: "DESTINATION_PRODUCT_SEARCH",
                    });
                });
                subscription.on(DestinationProductSearchClickEvent, (event) => {
                    void consumerTripEventProductClick({
                        itemId: event.details.item.id,
                        itemPosition: event.details.position,
                        productOrigin: "DESTINATION_PRODUCT_SEARCH",
                    });
                });
                subscription.on(SearchProductClickEvent, (event) => {
                    void consumerTripEventProductClick({
                        itemId: event.details.item.id,
                        itemPosition: event.details.position,
                        productOrigin: "SEARCH",
                    });
                });
            },

            // Questionnaire events
            (subscription) => {
                subscription.on(QuestionnaireOpenEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireOpened(event.details);
                });
                subscription.on(QuestionnaireInterestSelectEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireInterestSelected(event.details);
                });
                subscription.on(QuestionnaireInterestUnselectEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireInterestUnselected(event.details);
                });
                subscription.on(QuestionnaireNextClickEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireNextClicked(event.details);
                });
                subscription.on(QuestionnaireBackClickEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireBackClicked(event.details);
                });
                subscription.on(QuestionnaireCloseEvent, (event) => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireClosed(event.details);
                });
                subscription.on(QuestionnaireSubmitEvent, () => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireSubmitted();
                });
                subscription.on(QuestionnaireChangeOfHeartClickEvent, () => {
                    void consumerTripEventQuestionnaire.consumerTripEventQuesitonnaireChangeOfHeartClicked();
                });
            },

            //HBML
            (subscription) => {
                subscription.on(HbmlShelfFirstScrollEvent, (event) => {
                    void hbmlComponentShelfScrolled(event.details);
                });
            },
        ],
    });

    return <>{children}</>;
};
