import { useEffect } from "react";

import { CustomEventClass } from "../CustomEventShape";

export function useCustomDOMEventHandler(
    ref: React.RefObject<HTMLElement>,
    eventSubscription: (subscription: Subscription) => void
) {
    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const unregisterEventCallbackList: Array<() => void> = [];
        const subscription: Subscription = {
            on(eventClass, listener) {
                ref.current?.addEventListener(eventClass.NAME, listener as never, { passive: true });
                unregisterEventCallbackList.push(() => {
                    ref.current?.removeEventListener(eventClass.NAME, listener as never);
                });
            },
        };

        eventSubscription(subscription);

        return () => {
            for (const unregister of unregisterEventCallbackList) {
                unregister();
            }
        };
    }, [ref.current, eventSubscription]);
}

export interface Subscription {
    on<T extends CustomEventClass<string>>(eventClass: T, listener: (event: InstanceType<T>) => void): void;
}
