import { useCallback } from "react";

import type { HBMLEventComponentShelfScrolledInput } from "@holibob-packages/consumer-trip-event";

import { useConsumerTripEventCreateMutation } from "../../apiHooks/graphql";

export function useHBMLComponentShelfScrolledEvent({ consumerTripId }: { consumerTripId: string }) {
    const [consumerTripEventCreate] = useConsumerTripEventCreateMutation();

    return useCallback(
        (curationId: string) => {
            const event: HBMLEventComponentShelfScrolledInput = {
                consumerTripId,
                type: "HBML_COMPONENT_SHELF_SCROLLED",
                meta: { curationId },
            };

            void consumerTripEventCreate({ variables: event });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}
